import { Action, useStateContext } from '../providers/StateContext';

export default function useFnWrapper(fn) {
  const [, dispatch] = useStateContext();

  const wrappedFn = async (...args) => {
    try {
      return await fn.apply(null, args);
    } catch (e) {
      const error =
        (e.json && typeof e.json === 'function' && (await e.json())) || {};

      if (e.status && e.status === 401) {
        const { message } = error;
        dispatch({
          type: Action.SET_ERROR,
          error: {
            isError: true,
            title: 'Unauthorized',
            content: message,
          },
        });
      }
      return e;
    }
  };

  return [wrappedFn];
}
