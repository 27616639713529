import React, { useEffect, useState } from 'react';
import {
  Modal as StrapModal,
  Alert as StrapAlert,
  ModalBody,
} from 'reactstrap';

import MUIButton from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import MUISelect from '@material-ui/core/Select';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import '../../assets/styles/loader.scss';

export const Button = (props) => {
  const { classes, ...compProps } = props;
  return (
    <MUIButton className={`${classes} button-default`} {...compProps}>
      {props.label}
    </MUIButton>
  );
};

export const Select = (props) => {
  const { options, onChangeHandler, ...rest } = props;
  return (
    <React.Fragment>
      <MUISelect
        {...rest}
        onChange={(val) => {
          onChangeHandler(props.name, {
            name: options.find((option) => option.value === val.target.value)
              .name,
            value: val.target.value,
          });
        }}
      >
        {options &&
          options.map((option) => (
            <MenuItem key={`${option.name}`} value={`${option.value}`}>
              <div className="menu-item-wrapper">
                <RadioButtonUncheckedIcon className="icon-circle" />
                <span>{option.name}</span>
              </div>
            </MenuItem>
          ))}
      </MUISelect>
    </React.Fragment>
  );
};

export const Modal = (props) => {
  const [modal, setModal] = useState(false);
  useEffect(() => {
    setModal(props.showModal);
  }, [props.showModal]);

  return (
    <StrapModal isOpen={modal} toggle={props.toggle} className={props.classes}>
      <ModalBody>{props.children}</ModalBody>
    </StrapModal>
  );
};

export const Alert = (props) => {
  return (
    <StrapAlert color="danger" className="alert">
      <p>
        <i className="fa fa-exclamation-circle"></i>
        <span>{props.children}</span>
      </p>
    </StrapAlert>
  );
};

export const Loader = (props) => {
  return (
    <React.Fragment>
      {props.loading && <div className="lds-dual-ring"></div>}
    </React.Fragment>
  );
};
