const PatientSuccessIcon = {
  alt: 'patient-success-icon',
  src: require('./patient-submit-success-icon.png'),
};

const CloseIcon = {
  alt: 'close',
  src: require('./close-icon.png'),
};

const Banner = {
  alt: 'eqha-banner',
  src: 'https://cdn.swiperxapp.com/rt/eqha/eqha_banner_intro.jpg',
};

const Steps = {
  alt: 'rt-steps',
  src: require('./steps.jpg'),
};

const Check = {
  alt: 'rt-check',
  src: require('./tick.png'),
};

const DataSave = {
  alt: 'rt-save',
  src: require('./data_save.png'),
};

const ScreeningDone = {
  alt: 'rt-screen-done',
  src: require('./patient_done.png'),
};

const CalendarIcon = {
  alt: 'calendar-icon',
  src: require('./calendar.jpg'),
};

const TBBanner = {
  alt: 'tb-banner',
  src: 'https://cdn.swiperxapp.com/rt/eqha/tb_banner.jpg',
};

const STIBanner = {
  alt: 'sti-banner',
  src: 'https://cdn.swiperxapp.com/rt/eqha/sti_banner.jpg',
};

const RHBanner = {
  alt: 'rh-banner',
  src: 'https://cdn.swiperxapp.com/rt/eqha/rh_banner.jpg',
};

export const Image = {
  PatientSuccessIcon,
  CloseIcon,
  Banner,
  Steps,
  Check,
  DataSave,
  ScreeningDone,
  CalendarIcon,
  TBBanner,
  STIBanner,
  RHBanner,
};
