import React, { useState, useEffect } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import { API } from '../api';
import { Action, useStateContext } from '../providers/StateContext';
import { routes } from '../routes';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import SvgIcon from '@material-ui/core/SvgIcon';
import { Button } from '../components/common';
import useFnWrapper from '../hooks/useFnWrapper';
import isError from 'lodash/isError';

export const Screen = (props) => {
  const [{ form, screening }, dispatch] = useStateContext();
  const {
    match: { params },
  } = props;
  const { screenType } = params;
  const [getSymptoms] = useFnWrapper(API.getSymptoms);
  const [createScreenedReferral] = useFnWrapper(API.createScreenedReferral);

  const mapTitleByScreenType = (screenType) => {
    switch (screenType) {
      case 'tb':
        return 'Screen for TB';
      case 'rh':
        return 'Screen for Reproductive Health';
      default:
        return 'Screen for HIV/STI';
    }
  };

  const [symptoms, setSymptoms] = useState([]);
  const [selected, setSelected] = useState(
    Object.keys(screening).length === 0 ? [] : screening
  );
  const [hasSymptoms, setHasSymptoms] = useState(
    selected.length ? true : false
  );
  const [, setSymptomsLoading] = useState(true);
  const handleError = useErrorHandler();

  useEffect(() => {
    getSymptoms(screenType)
      .then((res) => {
        setSymptoms(res.data ? res.data : []);
        setSymptomsLoading(false);
      })
      .catch((err) => {
        console.error(err);
        handleError(err);
      });

    dispatch({
      type: Action.SET_NAME,
      name: mapTitleByScreenType(screenType),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, screenType]);

  useEffect(() => {
    setHasSymptoms(selected.filter((s) => s.val).length ?? true);
    dispatch({
      type: Action.SET_SCREENING,
      screening: selected,
    });
  }, [dispatch, selected]);

  useEffect(() => {
    const symptomsSelected = selected.length
      ? selected
      : symptoms.map((symptom) => ({ id: symptom.id, val: false }));
    setSelected(symptomsSelected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [symptoms]);

  const tick = (id) => {
    setSelected(
      selected.map((obj) => {
        return id === obj.id ? { id: obj.id, val: !obj.val } : obj;
      })
    );
  };

  const refer = () => {
    dispatch({
      type: Action.SET_FORM,
      form: {
        ...form,
        symptoms: selected
          .filter((symptom) => symptom.val)
          .map((symptom) => symptom.id),
        referType: screenType,
      },
    });

    props.history.push(routes.refer.path);
  };

  const done = async () => {
    try {
      const symptomsSelected = Object.values(selected)
        .filter((cb) => cb.val)
        .map((cb) => cb.id);

      const result = await createScreenedReferral(symptomsSelected, screenType);

      if (isError(result)) {
        console.error(result);
        return handleError(result);
      }

      dispatch({
        type: Action.SET_FORM,
        form: {
          ...form,
          symptoms: [],
          mobile: '',
          sex: form.sex || {
            name: '',
            value: null,
          },
          age: '',
          municipality: {
            name: '',
            value: null,
          },
          facility: {
            name: '',
            value: null,
          },
        },
      });

      return props.history.push(routes.home.path);
    } catch (error) {
      console.error(error);
      handleError(error);
    }
  };

  return (
    <React.Fragment>
      <div className="screening-form">
        <form>
          <Container className="checkbox-list">
            <Grid container spacing={2}>
              {symptoms.map((symptom) => (
                <Grid item key={`symptom-${symptom.id}`} xs={12}>
                  <Paper elevation={3} className="checkbox-group">
                    <FormControlLabel
                      control={
                        <Checkbox
                          name={`symptom-checkbox-${symptom.id}`}
                          className="checkbox"
                          disableRipple
                          checkedIcon={
                            <SvgIcon
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                              fill="none"
                            >
                              <rect
                                x="1"
                                y="1"
                                width="14"
                                height="14"
                                rx="2"
                                stroke="#14C47D"
                                strokeWidth="2"
                              />
                              <rect
                                x="3"
                                y="3"
                                width="10"
                                height="10"
                                rx="1"
                                fill="#14C47D"
                              />
                            </SvgIcon>
                          }
                          checked={
                            selected.find((item) => item.id === symptom.id)
                              ?.val ?? false
                          }
                          onChange={() => tick(symptom.id)}
                        />
                      }
                      label={symptom.description}
                    />
                  </Paper>
                </Grid>
              ))}
              <div className="button-list">
                <Button
                  classes="primary"
                  label="Refer a Client"
                  disabled={!hasSymptoms}
                  onClick={() => refer()}
                />
                <Button
                  classes="secondary"
                  label="End Screening"
                  disabled={!hasSymptoms}
                  onClick={() => done()}
                />
              </div>
            </Grid>
          </Container>
        </form>
      </div>
    </React.Fragment>
  );
};
