import { createBrowserHistory } from 'history';
import { Containers } from '../containers';

export const history = createBrowserHistory();

export const routes = {
  home: {
    name: 'Home',
    path: '/',
    exact: true,
    component: Containers.Home,
  },
  screen: {
    name: 'Screen',
    path: '/refer/:screenType/screening',
    exact: true,
    component: Containers.Screen,
  },
  select: {
    name: 'ScreenSelect',
    path: '/refer/select',
    exact: true,
    component: Containers.ScreenSelect,
  },
  refer: {
    name: 'Refer',
    path: '/refer/form',
    exact: true,
    component: Containers.Refer,
  },
  list: {
    name: 'Referrals',
    path: '/:userId/referrals',
    exact: true,
    component: Containers.Referrals,
  },
  error: {
    name: 'Error',
    path: '/error',
    exact: true,
    component: Containers.Error,
  },
  success: {
    name: 'Success',
    path: '/refer/success',
    exact: true,
    component: Containers.Success,
  },
  end: {
    name: 'ScreenEnd',
    path: '/refer/end',
    exact: true,
    component: Containers.ScreenEnd,
  },
  update: {
    name: 'UpdateReferral',
    path: '/referrals/update',
    exact: true,
    component: Containers.UpdateReferral,
  },
  auth: {
    name: 'Auth',
    path: '/auth',
    exact: true,
    component: Containers.Auth,
  },
  history: {
    name: 'History',
    path: '/history',
    exact: true,
    component: Containers.History,
  },
  confirm: {
    name: 'ConfirmDetails',
    path: '/refer/form/confirm',
    exact: true,
    component: Containers.ConfirmDetails,
  },
  record: {
    name: 'Record Details',
    path: '/referrals/:referralId',
    exact: true,
    component: Containers.RecordDetails,
  },
};
