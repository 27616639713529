import React from 'react';
import { Route } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorIcon from '@material-ui/icons/Error';
import { Button } from '../common';

const ErrorFallback = ({ error, componentStack, resetErrorBoundary }) => {
  return (
    <>
      <div role="alert" className="error">
        <div>
          <ErrorIcon className="icon-error" />
          <p className="error-title">Something went wrong.</p>
        </div>
      </div>
      <div className={'error-button-list'}>
        <Button
          classes={'primary'}
          label="Try again"
          onClick={resetErrorBoundary}
        />
      </div>
    </>
  );
};

export const RouteWithErrorBoundary = (props) => {
  return (
    <ErrorBoundary
      key={props.location?.pathname}
      FallbackComponent={ErrorFallback}
    >
      <Route {...props} />
    </ErrorBoundary>
  );
};
