import React, { useState } from 'react';
import { matchPath } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import HomeRoundedIcon from '@material-ui/icons/HomeRounded';
import { WarningPopup } from './WarningPopup';
import { Action, useStateContext } from '../../providers/StateContext';

export const AppHeader = (props) => {
  const [{ name, form }, dispatch] = useStateContext();
  const [warningPopup, setWarningPopup] = useState(false);
  const currentRoute = props.history.location.pathname;
  const homeButtonRoutes = ['/refer/form', '/refer/:screenType/screening'];
  const matchRoute = matchPath(currentRoute, {
    path: homeButtonRoutes,
    exact: true,
  });
  const referScreensRoute = [
    '/refer/tb/screening',
    '/refer/rh/screening',
    '/refer/sti/screening',
  ];
  const { referType } = form;

  const isHomeEnabled =
    matchRoute && homeButtonRoutes.includes(matchRoute.path) ? true : false;

  const isBackEnabled = currentRoute !== '/refer/success';

  const back = () => {
    if (currentRoute === '/') {
      return (window.location = 'swrx://exit');
    }

    if (currentRoute === '/history') {
      return props.history.push('/');
    }

    if (referScreensRoute.includes(currentRoute)) {
      return props.history.push('/refer/select');
    }

    if (currentRoute === '/refer/select') {
      return props.history.push('/');
    }

    if (referType && currentRoute === '/refer/form') {
      return props.history.push(`/refer/${referType}/screening`);
    }

    props.history.goBack();
  };

  const home = () => {
    setWarningPopup(true);
  };

  const handlePopupClose = () => {
    setWarningPopup(false);
  };

  const handlePopupLeave = () => {
    dispatch({
      type: Action.SET_FORM,
      form: {
        mobile: '',
        sex: {
          name: '',
          value: '',
        },
        age: '',
        municipality: {
          name: '',
          value: '',
        },
        facility: {
          name: '',
          value: '',
        },
        symptoms: [],
        userId: form?.userId,
      },
    });

    dispatch({
      type: Action.SET_SCREENING,
      screening: {},
    });

    setWarningPopup(false);

    props.history.push('/');
  };

  return (
    <>
      <AppBar position="fixed" className="app-header">
        <Toolbar>
          {isBackEnabled && (
            <IconButton
              aria-label="back"
              className="app-back"
              onClick={() => back()}
            >
              <KeyboardBackspaceIcon style={{ fontSize: 27 }} />
            </IconButton>
          )}
          <Typography
            variant="h2"
            className={`app-title ${isBackEnabled ? '' : 'push-to-right'}`}
            style={{ flex: 1 }}
          >
            {name || ''}
          </Typography>
          {isHomeEnabled && (
            <IconButton
              aria-label="home"
              className="app-home"
              onClick={() => home()}
            >
              <HomeRoundedIcon style={{ fontSize: 27 }} />
            </IconButton>
          )}
        </Toolbar>
      </AppBar>
      {isHomeEnabled && (
        <WarningPopup
          showDialog={warningPopup}
          handleClose={handlePopupClose}
          handleLeave={handlePopupLeave}
        />
      )}
    </>
  );
};
