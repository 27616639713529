import Joi from 'joi';

export const updateReferralSchema = Joi.object({
  age: Joi.number().min(1).max(99).allow(null),
  sex: Joi.string().allow(null),
});

export const referralSchema = Joi.object({
  name: Joi.string(),
  mobile_number: Joi.string().required(),
  age: Joi.number().min(1).max(99).allow(null),
  sex: Joi.string().allow(null),
  municipality: Joi.string().allow(null),
  facility: Joi.string().required(),
  birth_date: Joi.date().required(),
});
