import React, { useState, useEffect } from 'react';
import { API } from '../api';
import { Button } from '../components/common';
import { Action, useStateContext } from '../providers/StateContext';
import { Image } from '../assets/images';
import useFnWrapper from '../hooks/useFnWrapper';

export const ScreenEnd = (props) => {
  const [{ screening }, dispatch] = useStateContext();
  const [symptoms, setSymptoms] = useState([]);
  const [getSymptoms] = useFnWrapper(API.getSymptoms);
  const [createScreenedReferral] = useFnWrapper(API.createScreenedReferral);

  useEffect(() => {
    getSymptoms().then((res) => {
      setSymptoms(res.data ? res.data : []);
    });

    dispatch({
      type: Action.SET_NAME,
      name: 'Identifikasi gejala',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const done = async () => {
    const selected = Object.values(screening)
      .filter((cb) => cb.val)
      .map((cb) => cb.id);
    await createScreenedReferral(selected);

    window.location = 'swrx://close';
  };

  return (
    <React.Fragment>
      <div className="screening-done page">
        <div className="content">
          <img src={Image.ScreeningDone.src} alt={Image.ScreeningDone.alt} />
          <p>
            Pasien Anda menunjukkan{' '}
            {Object.values(screening).filter((cb) => cb.val).length} dari 10
            gejala dan pasien tidak akan dirujuk. Terima kasih telah melakukan
            identifikasi gejala.
          </p>
        </div>
        <div className="selected-symptoms">
          {symptoms.map((symptom) => (
            <div
              key={`symptom-list-${symptom.id}`}
              className={`symptom ${
                Object.values(screening).find(
                  (cb) => cb.id === symptom.id && cb.val
                )
                  ? 'selected'
                  : ''
              }`}
            >
              <img
                className="symptom-icon"
                src={symptom.thumbnail_url}
                alt="tb-symptom"
              />
              <p>{symptom.description}</p>
            </div>
          ))}
        </div>
      </div>
      <div className={'button-list'}>
        <Button classes={'secondary'} label="Tutup" onClick={() => done()} />
      </div>
      <div className="end-border"></div>
    </React.Fragment>
  );
};
