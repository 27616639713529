import { formatInternational } from '../providers/utils';
import moment from 'moment';
import { serviceContainer } from '../services/serviceContainer';
import isNil from 'lodash/isNil';

const defaults = {
  country: 'Cambodia',
  programId: 3,
  programCode: 'eqha-kh',
  platformCode: 'swip',
};

const responseMapper = (response) => response.data;
const responseWrapper = (response) =>
  response.then(responseMapper).catch((err) => {
    if (err.response.statusText === 'Internal Server Error') {
      throw new Error(response.statusText);
    }

    if (err.response.status === 401) {
      const unauthorizedError = new Error(response.statusText);
      unauthorizedError.status = 401;

      throw unauthorizedError;
    }

    throw err.response.data;
  });

const checkAuth = (userId) => {
  return responseWrapper(
    serviceContainer.rtHttp.httpClient.post('/auth/tb-authorize', {
      user_id:
        typeof userId === 'string' ? Number.parseInt(userId, 10) : userId,
    })
  );
};

const getSymptoms = (type) => {
  return responseWrapper(
    serviceContainer.rtHttp.httpClient.get(
      `/programs/${defaults.programId}/screening`,
      { params: { type } }
    )
  );
};

const getFacilities = (district) => {
  return responseWrapper(
    serviceContainer.rtHttp.httpClient.get(`/facilities`, {
      params: {
        municipality: district,
        country: defaults.country,
      },
    })
  );
};

const getDistricts = () => {
  return responseWrapper(
    serviceContainer.rtHttp.httpClient.get(`/facilities/districts`, {
      params: { country: defaults.country },
    })
  );
};

const createReferral = (subscriberData) => {
  return responseWrapper(
    serviceContainer.rtHttp.httpClient.post('/programs/refer', {
      first_name: subscriberData.name,
      mobile_number: formatInternational(subscriberData.mobile),
      age: subscriberData.age || null,
      sex: subscriberData.sex.value,
      facility_id: subscriberData.facility.value,
      program_code: defaults.programCode,
      platform_code: defaults.platformCode,
      subscriber_symptoms: subscriberData.symptoms,
      birth_date: subscriberData.birthDate,
      health_concern: subscriberData?.referType,
    })
  );
};

const createScreenedReferral = (symptoms, healthConcern) => {
  return responseWrapper(
    serviceContainer.rtHttp.httpClient.post('/programs/save', {
      program_code: defaults.programCode,
      platform_code: defaults.platformCode,
      subscriber_symptoms: symptoms,
      health_concern: healthConcern,
    })
  );
};

const findSubscribers = (subscriberData) => {
  return responseWrapper(
    serviceContainer.rtHttp.httpClient.get(`/subscribers/search`, {
      params: subscriberData,
    })
  );
};

const formatReferralsFilter = (filter) => ({
  ...filter,
  startDate: moment(filter.startDate).utc().format(),
  endDate: moment(filter.endDate).utc().format(),
  platformCode: defaults.platformCode,
  programId: defaults.programId,
  status: Array.isArray(filter.status)
    ? filter.status.map((status) => ['status', status])
    : null,
});

const isValidReferralFilter = ([key, value]) =>
  !(isNil(value) || ['status'].includes(key));

const getReferrals = (filter) => {
  const formattedFilters = formatReferralsFilter(filter);

  return responseWrapper(
    serviceContainer.rtHttp.httpClient.get(`/referrals`, {
      params: new URLSearchParams([
        ...Object.entries(formattedFilters).filter(isValidReferralFilter),
        ...(formattedFilters.status ?? []),
      ]),
    })
  );
};

const getReferral = (referralId) => {
  return responseWrapper(
    serviceContainer.rtHttp.httpClient.get(`/referrals/${referralId}`)
  );
};

const updateReferral = (referralId, subscriberData) => {
  return responseWrapper(
    serviceContainer.rtHttp.httpClient.put(`/referrals/${referralId}`, {
      age: subscriberData.age,
      sex: subscriberData.sex,
    })
  );
};

export const API = {
  getSymptoms,
  getFacilities,
  getDistricts,
  getReferrals,
  getReferral,
  createReferral,
  updateReferral,
  findSubscribers,
  checkAuth,
  createScreenedReferral,
};
