import React, { forwardRef } from 'react';
import { Select } from '../common';
import {
  Form as StrapForm,
  FormGroup as StrapFormGroup,
  Label,
  Input,
} from 'reactstrap';
import { genderTypes, monthTypes } from '../../api/types';

import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import MUISelect from '@material-ui/core/Select';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import PhoneInput from 'react-phone-number-input/input';

const FormGroup = (props) => {
  return (
    <StrapFormGroup className={props.className}>
      {props.children}
      <span className="error-message">{props.errorMessage}</span>
    </StrapFormGroup>
  );
};

const CustomPhoneInput = forwardRef((props, ref) => {
  return (
    <TextField
      {...props}
      inputRef={ref}
      name="mobile"
      id="mobile-number"
      label="MOBILE NUMBER*"
      variant="outlined"
      className="mobile-number-input"
      fullWidth
      InputLabelProps={{
        shrink: false,
      }}
    />
  );
});

const ConfirmationForm = (props) => {
  const { subscriberData, onAgree } = props;
  return (
    <div className="confirmation-form">
      <div className="content">
        <h4 className="form-title">
          <b>Konfirmasi</b>
        </h4>
        <p>
          Berikut adalah data pasien yang <br />
          akan dirujuk
        </p>
      </div>
      <StrapForm>
        <FormGroup>
          <Label>Nama</Label>
          <p> {subscriberData.name || ''}</p>
        </FormGroup>
        <FormGroup>
          <Label>HP</Label>
          <p> {subscriberData.mobile || ''}</p>
        </FormGroup>
        {subscriberData.age && (
          <FormGroup>
            <Label>Umur</Label>
            <p> {subscriberData.age || ''} tahun</p>
          </FormGroup>
        )}
        {subscriberData.sex.name && (
          <FormGroup>
            <Label>Jenis kelamin</Label>
            <p> {subscriberData.sex.name || ''}</p>
          </FormGroup>
        )}
        <FormGroup>
          <Label>Kota</Label>
          <p> {'Kota Medan'}</p>
        </FormGroup>
        <FormGroup>
          <Label>Fasilitas tujuan</Label>
          <p>
            {subscriberData.facility?.name}
            <span>{subscriberData.facilityAddress ?? ''}</span>
          </p>
        </FormGroup>
      </StrapForm>
      <div className="terms">
        <FormGroup check>
          <Label check>
            <Input
              type="checkbox"
              onClick={() => {
                onAgree();
              }}
            />
            Saya, sebagai tenaga kefarmasian, dengan ini menyatakan bahwa data
            ini benar dan saya telah menerima persetujuan dari pasien. Data ini
            hanya akan digunakan untuk keperluan rujukan.
          </Label>
        </FormGroup>
      </div>
    </div>
  );
};

const ReferralForm = (
  subscriberData,
  options,
  onChangeHandler,
  form,
  forceEnable,
  errorMessages = {}
) => {
  const { facilities } = options;
  return (
    <StrapForm>
      <FormGroup
        errorMessage={errorMessages?.name}
        className={`${errorMessages?.name ? 'error' : ''}`}
      >
        <Label for="mobile">Nama*</Label>
        <Input
          name="name"
          type="text"
          id="name"
          onChange={(e) => onChangeHandler(e.target.name, e.target.value)}
          value={subscriberData.name}
          disabled={forceEnable ? !forceEnable : !!form.name}
        />
      </FormGroup>
      <FormGroup
        errorMessage={errorMessages?.mobile}
        className={`${errorMessages?.mobile ? 'error' : ''}`}
      >
        <Label for="mobile">Nomor HP*</Label>
        <Input
          name="mobile"
          type="text"
          id="mobile"
          onChange={(e) => onChangeHandler(e.target.name, e.target.value)}
          value={subscriberData.mobile}
          disabled={forceEnable ? !forceEnable : !!form.mobile}
        />
      </FormGroup>
      <FormGroup
        errorMessage={errorMessages?.age}
        className={`${errorMessages?.age ? 'error' : ''}`}
      >
        <Label for="mobile">Umur</Label>
        <Input
          name="age"
          type="text"
          id="age"
          onChange={(e) => onChangeHandler(e.target.name, e.target.value)}
          value={subscriberData.age}
          disabled={forceEnable ? !forceEnable : !!form.age}
        />
      </FormGroup>
      <FormGroup>
        <Label for="mobile">Jenis kelamin</Label>
        <Select
          placeholder=" "
          onChangeHandler={onChangeHandler}
          default={0}
          name="sex"
          id="sex"
          value={subscriberData.sex.value}
          options={genderTypes}
          disabled={forceEnable ? !forceEnable : !!form.sex?.value}
        />
      </FormGroup>
      <FormGroup
        errorMessage={errorMessages?.municipality}
        className={`${errorMessages?.municipality ? 'error' : ''}`}
      >
        <Label for="mobile">
          Kota*
          <span>Isi sesuai lokaso apotek</span>
        </Label>
        <Select
          onChangeHandler={onChangeHandler}
          default={0}
          name="municipality"
          id="municipality"
          value={subscriberData.municipality.value}
          options={[
            {
              name: 'Kota Medan',
              value: 'Kota Medan',
            },
          ]}
          disabled={forceEnable ? !forceEnable : !!form.municipality?.value}
          placeholder=" "
        />
      </FormGroup>
      <FormGroup
        errorMessage={errorMessages?.facility}
        className={`${errorMessages?.facility ? 'error' : ''}`}
      >
        <Label for="mobile">
          Fasilitas rujukan*
          <span>Isi sesuai pilihan pasien</span>
        </Label>
        <Select
          onChangeHandler={onChangeHandler}
          default={0}
          name="facility"
          id="facility"
          value={subscriberData.facility.id}
          options={facilities.map((facility) => ({
            name: facility.name,
            value: facility.id,
          }))}
          search={true}
          disabled={forceEnable ? !forceEnable : !!form.facility?.value}
          placeholder=" "
        />
      </FormGroup>
    </StrapForm>
  );
};

const UpdateForm = (props) => {
  const { newData, subscriberData, onChangeHandler, errorMessages } = props;
  return (
    <div className="update-form">
      <StrapForm>
        <FormGroup>
          <Label>Nama</Label>
          <p> {subscriberData.name || ''}</p>
        </FormGroup>
        <FormGroup>
          <Label>HP</Label>
          <p> {subscriberData.mobile || ''}</p>
        </FormGroup>
        <FormGroup
          errorMessage={errorMessages?.age}
          className={`${errorMessages?.age ? 'error' : ''}`}
        >
          <Label className={`${!subscriberData.age ? 'no-val' : ''}`}>
            Umur
          </Label>
          {subscriberData.age ? (
            <p> {subscriberData.age || ''} tahun</p>
          ) : (
            <Input
              name="age"
              type="text"
              id="age"
              onChange={(e) => onChangeHandler(e.target.name, e.target.value)}
              value={subscriberData.age || newData.age}
            />
          )}
        </FormGroup>
        <FormGroup>
          <Label className={`${!subscriberData.sex.name ? 'no-val' : ''}`}>
            Jenis kelamin
          </Label>
          {subscriberData.sex.name ? (
            <p> {subscriberData.sex.name || ''}</p>
          ) : (
            <Select
              onChangeHandler={onChangeHandler}
              default={0}
              name="sex"
              id="sex"
              value={newData.sex.value || subscriberData.sex.value}
              options={genderTypes}
              placeholder=" "
            />
          )}
        </FormGroup>

        <FormGroup>
          <Label>Kota</Label>
          <p> {'Kota Medan'}</p>
        </FormGroup>
        <FormGroup>
          <Label>Fasilitas tujuan</Label>
          <p>
            {subscriberData.facility?.name}
            <span>{subscriberData.facility?.address ?? ''}</span>
          </p>
        </FormGroup>
      </StrapForm>
    </div>
  );
};

const ReferralFormV2 = (
  subscriberData,
  options,
  onChangeHandler,
  errorMessages = {}
) => {
  const { facilities } = options;
  return (
    <form>
      <Container className="form-container">
        <Grid container spacing={2}>
          <Grid
            item
            key="mobile-number"
            className={subscriberData.isBack ? 'indent-remove' : ''}
            xs={12}
          >
            {(!subscriberData.mobile || !subscriberData.isBack) && (
              <span className="mobile-prefix">+855</span>
            )}
            {/* <span className="mobile-prefix">+855</span> */}
            <PhoneInput
              defaultCountry="KH"
              useNationalFormatForDefaultCountryValue={false}
              onChange={(e) => onChangeHandler('mobile', e)}
              value={subscriberData.mobile}
              error={errorMessages?.mobile ? true : false}
              inputComponent={CustomPhoneInput}
            />
          </Grid>
          <Grid item key="gender" xs={12}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel shrink={false} id="demo-simple-select-outlined-label">
                Gender*
              </InputLabel>
              <Select
                name="sex"
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                label="Age"
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                  getContentAnchorEl: null,
                  classes: { paper: 'menu-list-wrapper height150' },
                }}
                options={genderTypes}
                onChangeHandler={onChangeHandler}
                value={subscriberData.sex.value}
              />
            </FormControl>
          </Grid>
          <Grid item key="age" xs={12}>
            <TextField
              name="age"
              id="age"
              label="AGE*"
              type="number"
              variant="outlined"
              fullWidth
              onInput={(e) => {
                e.target.value = Math.max(0, parseInt(e.target.value))
                  .toString()
                  .slice(0, 2);
              }}
              InputLabelProps={{
                shrink: false,
              }}
              onChange={(e) => onChangeHandler(e.target.name, e.target.value)}
              value={subscriberData.age}
              error={errorMessages?.age ? true : false}
            />
          </Grid>
          <Grid item key="birth-month" xs={7}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel shrink={false} id="demo-simple-select-outlined-label">
                BIRTH MONTH*
              </InputLabel>
              <Select
                name="birthMonth"
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                label="birth-month"
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                  transformOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  getContentAnchorEl: null,
                  classes: { paper: 'menu-list-wrapper height223' },
                }}
                options={monthTypes}
                onChangeHandler={onChangeHandler}
                value={subscriberData.birthMonth.value}
              />
            </FormControl>
          </Grid>
          <Grid item key="birth-day" xs={5}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel shrink={false} id="demo-simple-select-outlined-label">
                BIRTH DAY
              </InputLabel>
              <Select
                name="birthDay"
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                label="birth-day"
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                  transformOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  getContentAnchorEl: null,
                  classes: { paper: 'menu-list-wrapper height223' },
                }}
                options={[...Array(31).keys()].map((i) => ({
                  name: `${i + 1}`,
                  value: `${i + 1}`,
                }))}
                onChangeHandler={onChangeHandler}
                value={subscriberData.birthDay.value}
              />
            </FormControl>
          </Grid>
          <Grid item key="birth-year" xs={12}>
            <TextField
              name="birthYear"
              id="birth-year"
              label="BIRTH YEAR"
              type="number"
              onInput={(e) => {
                e.target.value = Math.max(0, parseInt(e.target.value))
                  .toString()
                  .slice(0, 4);
              }}
              variant="outlined"
              placeholder="Placeholder"
              fullWidth
              InputLabelProps={{
                shrink: false,
              }}
              onChange={(e) => onChangeHandler(e.target.name, e.target.value)}
              value={subscriberData.birthYear}
            />
          </Grid>
          <Grid item key="preferred-health-facility" xs={12}>
            <FormControl
              variant="outlined"
              fullWidth
              error={errorMessages?.facility ? true : false}
            >
              <InputLabel shrink={false} id="demo-simple-select-outlined-label">
                PREFERRED HEALTH FACILITY*
              </InputLabel>
              <MUISelect
                name="preferredHealthFacility"
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                label="facility"
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                  getContentAnchorEl: null,
                  classes: {
                    paper: 'menu-list-wrapper height150 menu-item-facility',
                  },
                }}
                onChange={(val) => {
                  onChangeHandler('preferredHealthFacility', {
                    name: facilities.find(
                      (option) => option.id === val.target.value
                    ).name,
                    value: val.target.value,
                  });
                }}
                value={
                  facilities && facilities?.length
                    ? subscriberData.preferredHealthFacility.value
                    : ''
                }
              >
                {facilities.map((facility) => (
                  <MenuItem key={facility.id} value={facility.id}>
                    <div className="menu-item-wrapper">
                      <RadioButtonUncheckedIcon className="icon-circle" />
                      <div className="facility-info">
                        <span className="facility-name">{facility.name}</span>
                        {facility?.address && (
                          <span className="address">{facility.address}</span>
                        )}
                        {facility?.operating_hours && (
                          <span className="operating-hours">
                            Operating Hours: {facility.operating_hours}
                          </span>
                        )}
                      </div>
                    </div>
                  </MenuItem>
                ))}
              </MUISelect>
            </FormControl>
          </Grid>
        </Grid>
      </Container>
    </form>
  );
};

export const Form = {
  ConfirmationForm,
  ReferralForm,
  UpdateForm,
  ReferralFormV2,
};
