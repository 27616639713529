import React, { useState, useEffect } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import Chip from '@material-ui/core/Chip';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import DatePicker from 'react-datepicker';
import ScrollContainer from 'react-indiana-drag-scroll';
import { API } from '../api';
import { routes } from '../routes';
import { Action, useStateContext } from '../providers/StateContext';
import moment from 'moment';
import useFnWrapper from '../hooks/useFnWrapper';

const statusOptions = [
  {
    label: 'All',
    value: 'all',
  },
  {
    label: 'Complete',
    value: ['complete'],
  },
  {
    label: 'Not Referred',
    value: ['pending', 'incomplete', 'screened'],
  },
];

const filterOptions = [
  {
    label: 'All',
    value: 'all',
  },
  {
    label: 'TB',
    value: 'tb',
  },
  {
    label: 'Reproductive Health',
    value: 'rh',
  },
  {
    label: 'HIV/STI',
    value: 'sti',
  },
];

const statuses = [
  {
    value: 'in-complete',
    label: 'IN COMPLETE',
    color: '#ff7d3e',
  },
  {
    value: 'complete',
    label: 'COMPLETE',
    color: '#1733DD',
  },
  {
    value: 'not-referred',
    label: 'NOT REFERRED',
    color: '#8F8F8F',
  },
];

export default function History(props) {
  const [{ filters }, dispatch] = useStateContext();
  const [startDate, setStartDate] = useState(filters.startDate);
  const [endDate, setEndDate] = useState(filters.endDate);
  const [activeFilter, setActiveFilter] = useState(filters.activeFilter);
  const [status, setStatus] = useState(filters.status);
  const [referrals, setReferrals] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const handleError = useErrorHandler();
  const [loadMore, setLoadMore] = useState(false);
  const [getReferrals] = useFnWrapper(API.getReferrals);

  useEffect(() => {
    dispatch({
      type: Action.SET_NAME,
      name: 'Referral History',
    });
  }, [dispatch]);

  useEffect(() => {
    getReferrals({
      referrer: localStorage.getItem('swrx:activeAccountId'),
      startDate,
      endDate,
      offset: 0,
      limit: 30,
      activeFilter,
      status,
    })
      .then(({ data, meta }) => {
        meta && meta.count && meta.count === referrals.length
          ? setLoadMore(false)
          : data && Array.isArray(data) && data.length >= 30
          ? setLoadMore(true)
          : setLoadMore(false);

        setReferrals(data);
        setIsLoaded(true);
      })
      .catch((e) => {
        console.error(e);
        handleError(e);
      })
      .finally(() => {
        dispatch({
          type: Action.SET_FILTERS,
          filters: {
            startDate,
            endDate,
            activeFilter,
            status,
          },
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeFilter, status, startDate, endDate, handleError, dispatch]);

  const loadMoreItems = (e) => {
    e.preventDefault();
    getReferrals({
      referrer: localStorage.getItem('swrx:activeAccountId'),
      startDate,
      endDate,
      offset: referrals.length,
      limit: 30,
      activeFilter,
      status,
    })
      .then(({ data, meta }) => {
        meta && meta.count && meta.count === referrals.length
          ? setLoadMore(false)
          : data && Array.isArray(data) && data.length >= 30
          ? setLoadMore(true)
          : setLoadMore(false);

        setReferrals([...referrals, ...data]);
      })
      .catch((e) => {
        console.error(e);
        handleError(e);
      });
  };

  const getStatusColor = (subscriberStatus) => {
    const findStatus = statuses.find(
      (status) =>
        status.value ===
        (subscriberStatus === 'pending' ||
        subscriberStatus === 'incomplete' ||
        subscriberStatus === 'screened'
          ? 'not-referred'
          : 'complete')
    );
    if (!findStatus) throw new Error(`status [${subscriberStatus}] no found`);
    return (
      <div
        className="history-status-container"
        style={{
          background: findStatus.color,
        }}
      >
        {findStatus.label}
      </div>
    );
  };

  const getHealthConcern = (healthConcern) => {
    const filter = filterOptions.find(
      (filter) => filter.value === healthConcern
    );
    if (filter && filter.value) return filter.label;
    return 'N/A';
  };

  const getFilters = (arr, filterValue, setter) => {
    return arr.map(({ label, value }, idx) => (
      <Chip
        key={idx}
        className="history-filter-item"
        style={{
          ...(filterValue === value
            ? { background: '#14C47D', color: 'white' }
            : { background: '#ECECEC', color: 'black' }),
        }}
        label={label}
        size="small"
        onClick={(e) => {
          e.preventDefault();
          setIsLoaded(false);
          setter(value);
        }}
      />
    ));
  };

  const goTo = (path) => {
    props.history.push(path);
  };

  const onItemClick = (referralId) => (e) => {
    e.preventDefault();
    goTo(routes.record.path.replace(':referralId', referralId));
  };

  const getItems = () => {
    if (!referrals || referrals.length === 0) return null;
    return referrals.map(({ id, subscriber }, idx) => (
      <div
        key={idx}
        className={subscriber.status === 'complete' ? 'history-item' : ''}
        style={{
          ...(isLoaded
            ? {
                animation: `slideFromBottom 500ms ${idx * 80}ms both ease-out`,
              }
            : {}),
        }}
        onClick={subscriber.status === 'complete' ? onItemClick(id) : null}
      >
        <ListItem alignItems="flex-start">
          <ListItemText
            primary={
              <Typography
                component="span"
                variant="subtitle1"
                className="history-list-item-primary"
              >
                {`${moment(subscriber.createdAt).format('L')} ${moment(
                  subscriber.createdAt
                ).format('LT')}`}
              </Typography>
            }
            secondary={
              <Typography variant="h5" className="history-list-item-secondary">
                {getHealthConcern(subscriber.healthConcern)}
              </Typography>
            }
          />
          {getStatusColor(subscriber.status)}
        </ListItem>
        <Divider variant="fullWidth" className="history-list-item-divider" />
      </div>
    ));
  };

  const customDatePickerInput = (label) => (
    <Chip
      size="small"
      label={label}
      style={{ background: '#ececec' }}
      onDelete={() => {}}
      deleteIcon={<ArrowDropDownIcon style={{ color: '#009117' }} />}
    />
  );

  return (
    <React.Fragment>
      <ScrollContainer className="scroll-container">
        <div className="history-menu-container ">
          <div className="history-filter-container">
            <p className="history-filter-item">Concern</p>
            {getFilters(filterOptions, activeFilter, setActiveFilter)}
          </div>
          <div className="history-filter-container">
            <p className="history-filter-item">Status</p>
            {getFilters(statusOptions, status, setStatus)}
          </div>
          <div className="history-filter-container history-date-filter-container">
            <p className="history-filter-item">Date</p>
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              customInput={customDatePickerInput(moment(startDate).format('L'))}
              maxDate={endDate}
            />
            <p className="history-filter-item" style={{ color: 'grey' }}>
              to
            </p>
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              customInput={customDatePickerInput(moment(endDate).format('L'))}
              minDate={startDate}
            />
          </div>
        </div>
        <List className="history-list">
          {getItems()}
          {loadMore && (
            <ListItem button={true} onClick={loadMoreItems}>
              <ListItemText
                primary={
                  <Typography variant="subtitle2" align="center">
                    Load More Items...
                  </Typography>
                }
              />
            </ListItem>
          )}
        </List>
      </ScrollContainer>
    </React.Fragment>
  );
}
