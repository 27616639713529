import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export const WarningPopup = (props) => {
  const [open, setOpen] = useState(false);
  useEffect(() => {
    setOpen(props.showDialog);
  }, [props.showDialog]);

  return (
    <Dialog
      className="warning-popup"
      open={open}
      onClose={props.handleClose}
      aria-labelledby="warning-dialog-title"
      aria-describedby="warning-dialog-description"
    >
      <DialogTitle id="warning-dialog-title">{'Leave page?'}</DialogTitle>
      <DialogContent>
        <DialogContentText id="warning-dialog-description">
          Are you sure you want to leave this page? You will lose all your
          progress.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          className="popup-button"
          onClick={props.handleClose}
          disableRipple={true}
        >
          Cancel
        </Button>
        <Button
          className="popup-button danger"
          onClick={props.handleLeave}
          autoFocus
          disableRipple={true}
        >
          Leave
        </Button>
      </DialogActions>
    </Dialog>
  );
};
