import React from 'react';
import { Router, Switch } from 'react-router-dom';
import { StylesProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core';
import { createMuiTheme } from '@material-ui/core/styles';
import { history, routes } from './routes';
import { StateContextProvider } from './providers/StateContext';
import { AppHeader } from './components/common/AppHeader';
import { RouteWithErrorBoundary } from './components/error/RouteWithErrorBoundary';
import ScrollToTop from './components/common/ScrollToTop';
import AlertDialog from './components/common/AlertDialog';

const theme = createMuiTheme({
  typography: {
    fontFamily: ['"Nunito Sans"', 'sans-serif'].join(','),
  },
});

export const App = () => {
  return (
    <StateContextProvider>
      <StylesProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <AppHeader history={history}></AppHeader>
          <AlertDialog />
          <div className="app-main">
            <Router history={history}>
              <ScrollToTop />
              <Switch>
                {Object.keys(routes).map((route) => (
                  <RouteWithErrorBoundary
                    key={routes[route].name.toLowerCase()}
                    {...routes[route]}
                  />
                ))}
              </Switch>
            </Router>
          </div>
        </ThemeProvider>
      </StylesProvider>
    </StateContextProvider>
  );
};
