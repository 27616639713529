import React, { useEffect } from 'react';
import { Action, useStateContext } from '../providers/StateContext';
import DoneIcon from '@material-ui/icons/Done';
import { Button } from '../components/common';
import { routes } from '../routes';

export const Success = (props) => {
  const [, dispatch] = useStateContext();

  useEffect(() => {
    dispatch({
      type: Action.SET_NAME,
      name: 'Refer a Client',
    });
  }, [dispatch]);

  const goTo = (path) => {
    props.history.push(path);
  };

  return (
    <>
      <div className="success-alert">
        <div>
          <DoneIcon className="icon-check" />
          <p className="success-title">
            You have successfully referred a client
          </p>
        </div>
      </div>
      <div className={'success-button-list'}>
        <Button
          classes={'primary'}
          label="Go to Home"
          onClick={() => goTo(routes.home.path)}
        />
        <Button
          classes={'primary'}
          label="Go to Referral History"
          onClick={() => goTo(routes.history.path)}
        />
      </div>
    </>
  );
};
