import React, { useState, useEffect } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { Action, useStateContext } from '../providers/StateContext';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { API } from '../api';
import { Loader } from '../components/common';
import useFnWrapper from '../hooks/useFnWrapper';

export const RecordDetails = (props) => {
  const [{ form }, dispatch] = useStateContext();
  const [referral, setReferral] = useState(null);
  const [referralsLoading, setReferralsLoading] = useState(true);
  const handleError = useErrorHandler();
  const [getReferral] = useFnWrapper(API.getReferral);

  let query = useParams();

  useEffect(
    () => {
      const fetchReferral = async () => {
        try {
          const res = await getReferral(query.referralId);
          setReferral(res.data);
          setReferralsLoading(false);
        } catch (error) {
          console.error(error);
          handleError(error);
        }
      };
      fetchReferral();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    dispatch({
      type: Action.SET_NAME,
      name: 'Referral Record',
    });
  }, [dispatch, form.referralId]);

  return (
    <React.Fragment>
      <div className="confirm-details">
        <Container>
          <Grid container>
            {referral !== null ? (
              <Grid item xs={12}>
                <div className="detail-item">
                  <span className="field-title">Referral date</span>
                  <p>
                    {moment(referral.createdAt).format('MM/DD/YYYY h:mm A')}
                  </p>
                </div>
                <div className="detail-item">
                  <span className="field-title">Health Concern</span>
                  <p className="text-uppercase">
                    {referral.subscriber.healthConcern}
                  </p>
                </div>
                <div className="detail-item">
                  <span className="field-title">Mobile Number</span>
                  <p>{`+855 ${referral.subscriber.mobileNumber}`}</p>
                </div>
                <div className="detail-item">
                  <span className="field-title">Gender</span>
                  <p className="text-capitalize">{referral.subscriber.sex}</p>
                </div>
                <div className="detail-item">
                  <span className="field-title">Age</span>
                  <p>{referral.subscriber.age}</p>
                </div>
                <div className="detail-item">
                  <span className="field-title">Birth Month</span>
                  <p className="text-capitalize">
                    {moment(referral.subscriber.birthDate).format('MMMM')}
                  </p>
                </div>
                <div className="detail-item">
                  <span className="field-title">Preferred Health Facility</span>
                  <p className="facility-name">
                    {referral.subscriber.facility.name}
                  </p>
                  <span className="facility-info">
                    {referral.subscriber.facility.address}
                    <br />
                    {referral.subscriber.facility.operating_hours || ' '}
                    <br />
                    {referral.subscriber.facility.mobile_number}
                    <br />
                    {referral.subscriber.facility.email_address}
                  </span>
                </div>
                {referral.subscriber.facility.doctor_in_charge && (
                  <div className="detail-item">
                    <span className="field-title">Doctor in charge</span>
                    <p className="text-capitalize">
                      {referral.subscriber.facility.doctor_in_charge}
                    </p>
                  </div>
                )}
                {referral.subscriber.facility.personnel[0] &&
                  referral.subscriber.facility.personnel[0].name && (
                    <div className="detail-item">
                      <span className="field-title">
                        Health Facility Personnel
                      </span>
                      <p className="text-capitalize">
                        {referral.subscriber.facility.personnel[0].name}
                      </p>
                    </div>
                  )}
              </Grid>
            ) : (
              <Loader loading={referralsLoading} />
            )}
          </Grid>
        </Container>
      </div>
    </React.Fragment>
  );
};
