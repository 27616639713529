/* eslint-disable no-throw-literal */
import React, { useState, useEffect } from 'react';
import { Button } from '../components/common';
import { updateReferralSchema } from '../validation';
import { Action, useStateContext } from '../providers/StateContext';
import { API } from '../api';
import { Form } from '../components/forms';
import { routes } from '../routes';
import { AgeException } from '../api/CustomExceptions';
import { statusTypes } from '../api/types';
import useFnWrapper from '../hooks/useFnWrapper';

export const UpdateReferral = (props) => {
  const [{ form }, dispatch] = useStateContext();
  const [updateReferral] = useFnWrapper(API.updateReferral);
  const [errorMessage, setErrorMessage] = useState(null);
  const [subscriberData] = useState({
    name: form.name ? form.name : '',
    mobile: form.mobile ? `0${form.mobile}` : '',
    sex: form.sex || {
      name: '',
      value: null,
    },
    age: form.age || '',
    municipality: form.municipality || {
      name: '',
      value: null,
    },
    facility: form.facility || {
      name: '',
      value: null,
    },
    referralId: form.referralId,
  });
  const [newData, setNewData] = useState({
    sex: {
      name: '',
      value: null,
    },
    age: '',
  });

  useEffect(() => {
    dispatch({
      type: Action.SET_NAME,
      name: `Pasien #${form.referralId}`,
    });
  }, [dispatch, form.referralId]);

  const submit = async () => {
    try {
      const { age, sex } = newData;
      const data = {
        age: age.length ? age : null,
        sex: sex.value,
      };

      const { error } = updateReferralSchema.validate(data);

      if (error) {
        if (error.message.indexOf('age') > 0) {
          throw new AgeException('usia harus angka yang valid');
        }
      }

      if (!error) {
        if (form.age === '') {
          Object.assign(form, {
            age: null,
          });
        }

        setErrorMessage(null);

        await updateReferral(subscriberData.referralId, data);
        props.history.push(routes.success.path);
      }
    } catch (e) {
      if (e instanceof AgeException) {
        setErrorMessage({
          age: e.message,
        });
      }
    }
  };

  const onChangeHandler = (name, value) => {
    setNewData({
      ...newData,
      [name]: value,
    });
  };

  const referralMsg = () => {
    switch (form.status) {
      case statusTypes.Declined:
        return `Pasien Anda menunjukkan  ${
          form.symptoms?.length || 0
        } dari 10 gejala
        dan pasien tidak bersedia melanjutkan proses.`;
      case statusTypes.Pending:
        return `Pasien Anda menunjukkan  ${
          form.symptoms?.length || 0
        } dari 10 gejala
        dan menunggu konfirmasi pasien.`;
      case statusTypes.Screened:
        return `Pasien Anda menunjukkan  ${
          form.symptoms?.length || 0
        } dari 10 gejala
        dan pasien tidak bersedia melanjutkan proses.`;
      default:
        return `Pasien Anda menunjukkan  ${
          form.symptoms?.length || 0
        } dari 10 gejala
        dan menunggu konfirmasi pasien.`;
    }
  };

  return (
    <React.Fragment>
      <div className="referral-update">
        <div className={`symptoms-banner ${form.status}`}>
          <p>{referralMsg()}</p>
        </div>
        <div className="referral-form update">
          {form.status !== statusTypes.Screened && (
            <Form.UpdateForm
              newData={newData}
              subscriberData={subscriberData}
              onChangeHandler={onChangeHandler}
              errorMessages={errorMessage}
            />
          )}
          <div className="selected-symptoms">
            {form.symptoms?.map((symptom) => (
              <div
                key={`symptom-list-${symptom.id}`}
                className="symptom selected"
              >
                <img
                  className="symptom-icon"
                  src={symptom.thumbnail_url}
                  alt="tb-symptom"
                />
                <p>{symptom.description}</p>
              </div>
            ))}
          </div>
          {form.status === statusTypes.Incomplete && (
            <div className={'button-list'}>
              <Button
                classes={'secondary'}
                label="Lengkapi data"
                onClick={() => submit()}
              />
            </div>
          )}
        </div>
        <div className="end-border"></div>
      </div>
    </React.Fragment>
  );
};
