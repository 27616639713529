import React, { useEffect, useState } from 'react';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import { useErrorHandler } from 'react-error-boundary';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { Action, useStateContext } from '../providers/StateContext';
import { Button } from '../components/common';
import { routes } from '../routes';
import moment from 'moment';
import { API } from '../api';
import useFnWrapper from '../hooks/useFnWrapper';
import isError from 'lodash/isError';

export const ConfirmDetails = (props) => {
  const [createReferral] = useFnWrapper(API.createReferral);
  const [{ form }, dispatch] = useStateContext();
  const [subscriberData] = useState({
    mobile: form.mobile ? `${formatPhoneNumberIntl(form.mobile)}` : '',
    birthDate: form?.birthDate ?? null,
    sex: form.sex || {
      name: '',
      value: null,
    },
    age: form.age || '',
    municipality: form.municipality || {
      name: '',
      value: null,
    },
    facility: form.facility || {
      name: '',
      value: null,
    },
    referralId: form.referralId,
    birthMonth: form?.birthMonth || {
      name: '',
      value: null,
    },
    birthDay: form?.birthDay || {
      name: '',
      value: null,
    },
    birthYear: form?.birthYear ?? '',
    referType: form?.referType ?? null,
    preferredHealthFacility: form?.preferredHealthFacility || {
      name: '',
      value: '',
    },
    facilityAddress: form?.facilityAddress ?? '',
    facilityOperatingHours: form?.facilityOperatingHours ?? '',
    facilityEmail: form?.facilityEmail ?? '',
    facilityMobileNumber: form?.facilityMobileNumber ?? '',
    facilityName: form?.facilityName ?? '',
    facilityDoctorInCharge: form?.facilityDoctorInCharge ?? '',
    facilityPersonnelInCharge: form?.facilityPersonnelInCharge ?? '',
  });
  const handleError = useErrorHandler();

  useEffect(() => {
    dispatch({
      type: Action.SET_NAME,
      name: 'Confirm Details',
    });
  }, [dispatch]);

  const goBack = () => {
    dispatch({
      type: Action.SET_FORM,
      form: {
        ...form,
        ...subscriberData,
        isBack: true,
      },
    });

    props.history.push(routes.refer.path);
  };

  const submit = async () => {
    try {
      const result = await createReferral(form);

      if (isError(result)) {
        console.error(result);
        return handleError(result);
      }

      dispatch({
        type: Action.SET_FORM,
        form: {
          name: '',
          mobile: '',
          sex: {
            name: '',
            value: null,
          },
          age: '',
          municipality: {
            name: '',
            value: null,
          },
          facility: {
            name: '',
            value: null,
          },
          symptoms: [],
        },
      });

      dispatch({
        type: Action.SET_SCREENING,
        screening: {},
      });

      props.history.push(routes.success.path);
    } catch (error) {
      console.error(error);
      handleError(error);
    }
  };

  return (
    <React.Fragment>
      <div className="confirm-details">
        <Container>
          <Grid container>
            <Grid item xs={12}>
              <span className="direction">
                Please review the following information. Once submitted, you
                cannot edit anymore
              </span>
              <div className="detail-item">
                <span className="field-title">Referral date</span>
                <p>{moment().format('MM/DD/YYYY h:mm A')}</p>
              </div>
              {subscriberData?.referType && (
                <div className="detail-item">
                  <span className="field-title">Health Concern</span>
                  <p className="text-uppercase">{subscriberData.referType}</p>
                </div>
              )}
              <div className="detail-item">
                <span className="field-title">Mobile Number</span>
                <p>{`${subscriberData.mobile}`}</p>
              </div>
              <div className="detail-item">
                <span className="field-title">Gender</span>
                <p className="text-capitalize">{subscriberData.sex.value}</p>
              </div>
              <div className="detail-item">
                <span className="field-title">Age</span>
                <p>{subscriberData.age}</p>
              </div>
              <div className="detail-item">
                <span className="field-title">Birth Month</span>
                <p className="text-capitalize">
                  {subscriberData.birthMonth.value}
                </p>
              </div>
              <div className="detail-item">
                <span className="field-title">Preferred Health Facility</span>
                <p className="facility-name">
                  {subscriberData.preferredHealthFacility.name}
                </p>
                <span className="facility-info">
                  {subscriberData?.facilityAddress && (
                    <>
                      {subscriberData?.facilityAddress}
                      <br />
                    </>
                  )}
                  {subscriberData?.facilityOperatingHours && (
                    <>
                      {subscriberData.facilityOperatingHours}
                      <br />
                    </>
                  )}
                  {subscriberData?.facilityMobileNumber && (
                    <>
                      {subscriberData.facilityMobileNumber}
                      <br />
                    </>
                  )}
                  {subscriberData?.facilityEmail &&
                    subscriberData.facilityEmail}
                </span>
              </div>
              {subscriberData?.facilityDoctorInCharge && (
                <div className="detail-item">
                  <span className="field-title">Doctor in charge</span>
                  <p className="text-capitalize">
                    {subscriberData?.facilityDoctorInCharge}
                  </p>
                </div>
              )}
              {subscriberData?.facilityPersonnelInCharge && (
                <div className="detail-item">
                  <span className="field-title">Health Facility Personnel</span>
                  <p className="text-capitalize">
                    {subscriberData?.facilityPersonnelInCharge}
                  </p>
                </div>
              )}
            </Grid>
            <div className="button-list">
              <Button
                classes="primary"
                label="Confirm and Submit"
                onClick={() => submit()}
              />
              <Button
                classes="outlined"
                label="Go Back and Edit"
                onClick={() => goBack()}
              />
            </div>
          </Grid>
        </Container>
      </div>
    </React.Fragment>
  );
};
