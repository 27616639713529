import {
  formatPhoneNumber,
  formatPhoneNumberIntl,
} from 'react-phone-number-input';

export const sanitizeMobileNumber = (mobile_number) =>
  formatPhoneNumber(mobile_number).replace(/\s+/g, '').trim();

export const formatInternational = (mobile_number) =>
  formatPhoneNumberIntl(mobile_number).replace(/\s+/g, '').trim();
