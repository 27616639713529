import React from "react";
import { Button } from "./index";

export const ButtonList = (props) => {
  const [buttonLabel1, buttonLabel2] = props.labels;
  const { handler1, handler2 } = props.buttonHandlers;
  return (
    <div className="button-list">
      <Button
        classes="primary"
        label={buttonLabel1}
        onClick={handler1}
      />
      <Button
        classes="secondary"
        label={buttonLabel2}
        onClick={handler2}
      />
    </div>
  );
};