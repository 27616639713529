/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { Action, useStateContext } from '../providers/StateContext';
import { Image } from '../assets/images';

export const ScreenSelect = (props) => {
  const [, dispatch] = useStateContext();

  useEffect(() => {
    dispatch({
      type: Action.SET_NAME,
      name: 'Screen a Client',
    });
  }, [dispatch]);

  const goTo = (path) => {
    dispatch({
      type: Action.SET_SCREENING,
      screening: {},
    });
    props.history.push(path);
  };

  return (
    <>
      <div className="screen-select">
        <Grid
          container
          direction="row"
          alignItems="stretch"
          justify="center"
          style={{ height: 'calc(100vh - 47px)' }}
          spacing={1}
        >
          <Grid item className="banner-item">
            <a href="#" onClick={() => goTo('/refer/tb/screening')}>
              <Paper
                className="banner-image-container"
                style={{ backgroundImage: `url(${Image.TBBanner.src})` }}
              ></Paper>
            </a>
          </Grid>
          <Grid item className="banner-item">
            <a href="#" onClick={() => goTo('/refer/rh/screening')}>
              <Paper
                className="banner-image-container"
                style={{ backgroundImage: `url(${Image.RHBanner.src})` }}
              ></Paper>
            </a>
          </Grid>
          <Grid item className="banner-item">
            <a href="#" onClick={() => goTo('/refer/sti/screening')}>
              <Paper
                className="banner-image-container"
                style={{ backgroundImage: `url(${Image.STIBanner.src})` }}
              ></Paper>
            </a>
          </Grid>
        </Grid>
      </div>
    </>
  );
};
