/* eslint-disable no-throw-literal */
import React, { useState, useEffect } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import {
  isPossiblePhoneNumber,
  isValidPhoneNumber,
  parsePhoneNumber,
} from 'react-phone-number-input';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { Button } from '../components/common';
import { referralSchema } from '../validation';
import { Action, useStateContext } from '../providers/StateContext';
import { API } from '../api';
import { Form } from '../components/forms';
import { routes } from '../routes';
import {
  MobileNumberException,
  AgeException,
  MunicipalityException,
  FacilityException,
} from '../api/CustomExceptions';
import moment from 'moment';
import { sanitizeMobileNumber } from '../providers/utils';
import { supportedCountryCodeTypes } from '../api/types';
import useFnWrapper from '../hooks/useFnWrapper';

export const Refer = (props) => {
  const [{ form }, dispatch] = useStateContext();
  const [getDistricts] = useFnWrapper(API.getDistricts);
  const [getFacilities] = useFnWrapper(API.getFacilities);
  const [findSubscribers] = useFnWrapper(API.findSubscribers);
  const [facilities, setFacilities] = useState([]);
  const [municipalities, setMunicipalities] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [subscriberData, setSubscriberData] = useState({
    mobile: form.mobile ? `${form.mobile}` : '',
    birthDate: form?.birthDate ?? null,
    sex: form.sex || {
      name: '',
      value: '',
    },
    age: form.age || '',
    municipality: form.municipality || {
      name: '',
      value: null,
    },
    facility: form.facility || {
      name: '',
      value: '',
    },
    referType: form?.referType ?? null,
    referralId: form.referralId,
    birthMonth: form?.birthMonth || {
      name: '',
      value: '',
    },
    birthDay: form?.birthDay || {
      name: '',
      value: '',
    },
    birthYear: form?.birthYear ?? '',
    preferredHealthFacility: form?.preferredHealthFacility || {
      name: '',
      value: '',
    },
    facilityAddress: form?.facilityAddress ?? '',
    facilityOperatingHours: form?.facilityOperatingHours ?? '',
    facilityEmail: form?.facilityEmail ?? '',
    facilityMobileNumber: form?.facilityMobileNumber ?? '',
    facilityName: form?.facilityName ?? '',
    facilityDoctorInCharge: form?.facilityDoctorInCharge ?? '',
    facilityPersonnelInCharge: form?.facilityPersonnelInCharge ?? '',
    isBack: (form?.isBack || form.mobile) ?? false,
  });
  const [isFormValid, setIsFormValid] = useState(false);
  const handleError = useErrorHandler();

  const generateAgeByBirthDate = (birthMonth, birthDay, birthYear) => {
    const now = moment();
    const currentBirthDate = moment(
      `${birthMonth} ${birthDay}, ${birthYear}`,
      'MMMM DD, YYYY'
    );
    return Math.abs(now.diff(currentBirthDate, 'years'));
  };

  useEffect(() => {
    getDistricts()
      .then((res) => {
        setMunicipalities(res.items);
      })
      .catch((err) => {
        console.error(err);
        handleError(err);
      });

    dispatch({
      type: Action.SET_NAME,
      name: 'Refer a Client',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, handleError]);

  useEffect(() => {
    if (
      (subscriberData.birthDay.value &&
        subscriberData.birthYear &&
        subscriberData.birthMonth.value) ||
      (subscriberData.birthMonth.value && subscriberData.birthYear)
    ) {
      setSubscriberData({
        ...subscriberData,
        birthDate: moment(
          `${subscriberData.birthMonth.value} ${subscriberData.birthDay.value}, ${subscriberData.birthYear}`,
          ['MMMM DD, YYYY', 'MMMM, YYYY']
        ).format('YYYY-MM-DD'),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    subscriberData.birthDay.value,
    subscriberData.birthYear,
    subscriberData.birthMonth.value,
    subscriberData.age,
  ]);

  useEffect(() => {
    getFacilities(municipalities[0])
      .then((res) => {
        setFacilities(res);
      })
      .catch((err) => {
        console.error(err);
        handleError(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleError, municipalities]);

  useEffect(() => {
    const {
      mobile,
      sex,
      age,
      birthMonth,
      preferredHealthFacility,
    } = subscriberData;

    setIsFormValid(
      mobile &&
        sex.value &&
        age &&
        birthMonth.value &&
        preferredHealthFacility.value
    );
  }, [subscriberData]);

  const gotToConfirm = async () => {
    setErrorMessage(null);

    try {
      const {
        mobile,
        age,
        sex,
        facility,
        municipality,
        birthDate,
      } = subscriberData;
      const { error } = referralSchema.validate({
        mobile_number: sanitizeMobileNumber(mobile),
        age: age.length ? age : null,
        sex: sex.value,
        facility: facility.name,
        municipality: municipality.name || null,
        birth_date: birthDate,
      });

      if (error) {
        if (error.message.indexOf('mobile_number')) {
          if (error.message.indexOf('required') > 0) {
            throw new MobileNumberException('Mobile number required');
          }
        }

        if (error.message.indexOf('age') > 0) {
          throw new AgeException('age must be a valid number');
        }

        if (error.message.indexOf('municipality') > 0) {
          throw new MunicipalityException('municipality required');
        }

        if (error.message.indexOf('facility') > 0) {
          throw new FacilityException('facility required');
        }
      }

      if (mobile) {
        const phoneUtil = PhoneNumberUtil.getInstance();
        if (!isPossiblePhoneNumber(mobile) || !isValidPhoneNumber(mobile)) {
          throw new MobileNumberException('Invalid mobile number');
        }

        const phoneNumber = parsePhoneNumber(mobile);

        if (
          phoneNumber &&
          !supportedCountryCodeTypes.includes(phoneNumber.country)
        ) {
          throw new MobileNumberException('Unsupported mobile number');
        }

        const number = phoneUtil.parseAndKeepRawInput(
          mobile,
          phoneNumber.country
        );

        if (!phoneUtil.isValidNumber(number)) {
          throw new MobileNumberException('Invalid mobile number');
        }
      }

      let subscribersExist;
      if (!form.mobile || subscriberData.mobile) {
        subscribersExist = await findSubscribers({
          mobileNumber: sanitizeMobileNumber(mobile).slice(1),
          user_id: form?.userId || null,
        }).then((res) => (res.meta.count ? true : false));

        if (subscribersExist) {
          throw new MobileNumberException('this number has been used');
        }
      }

      if (!error && !subscribersExist) {
        dispatch({
          type: Action.SET_FORM,
          form: {
            ...form,
            ...subscriberData,
          },
        });

        setErrorMessage(null);
        props.history.push(routes.confirm.path);
      }
    } catch (e) {
      if (e instanceof MobileNumberException) {
        return setErrorMessage({
          mobile: e.message,
        });
      }

      if (e instanceof AgeException) {
        return setErrorMessage({
          age: e.message,
        });
      }

      if (e instanceof MunicipalityException) {
        return setErrorMessage({
          municipality: e.message,
        });
      }

      if (e instanceof FacilityException) {
        return setErrorMessage({
          facility: e.message,
        });
      }

      if (e instanceof Error) {
        handleError(e);
      }
    }
  };

  const onChangeHandler = (name, value) => {
    if (name === 'facility' || name === 'preferredHealthFacility') {
      const {
        name,
        address,
        operating_hours,
        email_address,
        mobile_number,
        doctor_in_charge,
        personnel,
      } = facilities.find((f) => f.id === value.value);

      setSubscriberData({
        ...subscriberData,
        facilityAddress: address,
        facility: value,
        facilityOperatingHours: operating_hours,
        facilityEmail: email_address,
        facilityMobileNumber: mobile_number,
        facilityName: name,
        facilityDoctorInCharge: doctor_in_charge,
        facilityPersonnelInCharge: personnel[0] && personnel[0].name,
        preferredHealthFacility: {
          name,
          value: value.value,
        },
      });
    } else if (name === 'age' && subscriberData.birthMonth.value && value) {
      if (subscriberData.birthMonth.value && subscriberData.birthDay.value) {
        const offsetYear = moment().diff(
          moment(
            `${subscriberData.birthMonth.value} ${subscriberData.birthDay.value}`,
            'MMMM DD'
          ).subtract(value, 'years'),
          'years'
        );
        const computedBirthYear = moment(
          `${subscriberData.birthMonth.value} ${subscriberData.birthDay.value}`,
          'MMMM DD'
        )
          .subtract(+value + (+value - offsetYear), 'years')
          .format('YYYY');

        setSubscriberData({
          ...subscriberData,
          birthYear: computedBirthYear,
          [name]: value,
        });
      } else if (subscriberData.birthMonth.value && value) {
        const computedBirthYear = moment()
          .month(subscriberData.birthMonth.value)
          .subtract(value, 'year')
          .format('YYYY');

        setSubscriberData({
          ...subscriberData,
          birthYear: computedBirthYear,
          [name]: value,
        });
      } else {
        setSubscriberData({
          ...subscriberData,
          [name]: value,
        });
      }
    } else if (name === 'birthMonth' && value.value) {
      if (
        subscriberData.birthYear &&
        subscriberData.birthDay.value &&
        value.value
      ) {
        const age = generateAgeByBirthDate(
          value.value,
          subscriberData.birthDay.value,
          subscriberData.birthYear
        );

        setSubscriberData({
          ...subscriberData,
          age,
          [name]: value,
        });
      } else if (
        subscriberData.age &&
        subscriberData.birthYear &&
        value.value
      ) {
        const now = moment();
        const currentBirthDate = moment(
          `${value.value}, ${subscriberData.birthYear}`,
          'MMMM, YYYY'
        );
        const computedAge = Math.abs(now.diff(currentBirthDate, 'years'));

        setSubscriberData({
          ...subscriberData,
          age: computedAge,
          [name]: value,
        });
      } else if (subscriberData.age && value.value) {
        const offsetYear = moment().diff(
          moment().month(value.value).subtract(subscriberData.age, 'years'),
          'years'
        );
        const computedBirthYear = moment()
          .month(value.value)
          .subtract(
            +subscriberData.age + (+subscriberData.age - offsetYear),
            'years'
          )
          .format('YYYY');

        setSubscriberData({
          ...subscriberData,
          birthYear: computedBirthYear,
          [name]: value,
        });
      } else if (value.value && subscriberData.birthYear) {
        const now = moment();
        const currentBirthDate = moment(
          `${value.value}, ${subscriberData.birthYear}`,
          'MMMM, YYYY'
        );
        const computedAge = Math.abs(now.diff(currentBirthDate, 'years'));

        setSubscriberData({
          ...subscriberData,
          age: computedAge,
          [name]: value,
        });
      } else {
        setSubscriberData({
          ...subscriberData,
          [name]: value,
        });
      }
    } else if (name === 'birthDay' && value.value) {
      if (
        subscriberData.birthYear &&
        subscriberData.birthMonth.value &&
        value.value
      ) {
        const age = generateAgeByBirthDate(
          subscriberData.birthMonth.value,
          value.value,
          subscriberData.birthYear
        );

        setSubscriberData({
          ...subscriberData,
          age,
          [name]: value,
        });
      } else {
        setSubscriberData({
          ...subscriberData,
          [name]: value,
        });
      }
    } else if (name === 'birthYear' && value && value.length === 4) {
      if (
        subscriberData.birthMonth.value &&
        subscriberData.birthDay.value &&
        value
      ) {
        const age = generateAgeByBirthDate(
          subscriberData.birthMonth.value,
          subscriberData.birthDay.value,
          value
        );

        setSubscriberData({
          ...subscriberData,
          age,
          [name]: value,
        });
      } else if (subscriberData.birthMonth.value && value) {
        const now = moment();
        const currentBirthDate = moment(
          `${subscriberData.birthMonth.value}, ${value}`,
          'MMMM, YYYY'
        );
        const computedAge = Math.abs(now.diff(currentBirthDate, 'years'));

        setSubscriberData({
          ...subscriberData,
          age: computedAge,
          [name]: value,
        });
      } else {
        setSubscriberData({
          ...subscriberData,
          [name]: value,
        });
      }
    } else if (name === 'mobile') {
      if (value) {
        setSubscriberData({
          ...subscriberData,
          [name]: value,
        });
      } else {
        setSubscriberData({
          ...subscriberData,
          [name]: '',
          isBack: false,
        });
      }
    } else {
      setSubscriberData({
        ...subscriberData,
        [name]: value,
      });
    }
  };

  return (
    <React.Fragment>
      <div className="referral-form">
        {errorMessage && (
          <span className="error-message">
            {Object.values(errorMessage)[0]}
          </span>
        )}
        {Form.ReferralFormV2(
          subscriberData,
          { municipalities, facilities },
          onChangeHandler,
          errorMessage
        )}
        <div className="button-list">
          <Button
            classes="primary"
            label="Submit"
            disabled={!isFormValid}
            onClick={() => gotToConfirm()}
          />
        </div>
      </div>
    </React.Fragment>
  );
};
