import React, { useEffect } from 'react';
import { Loader } from '../components/common';
import { routes } from '../routes';
import { Action, useStateContext } from '../providers/StateContext';
import { serviceContainer } from '../services/serviceContainer';
import { API } from '../api';

export const Auth = (props) => {
  const [{ form }, dispatch] = useStateContext();

  useEffect(() => {
    serviceContainer.auth
      .getCredentials(props.location)
      .then(({ userId }) => Promise.all([API.checkAuth(userId), userId]))
      .then(([res, userId]) => {
        if (res.authorized) {
          dispatch({
            type: Action.SET_FORM,
            form: {
              ...form,
              userId,
            },
          });

          dispatch({
            type: Action.SET_ALLOW_ACCESS,
            isAuthorized: true,
          });
        }
      })
      .catch((e) => console.error(e))
      .finally(() => props.history.push(routes.home.path));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, form, props.history, props.location.search]);

  return (
    <React.Fragment>
      <Loader loading={true}></Loader>
    </React.Fragment>
  );
};
