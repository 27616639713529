import React, { useEffect } from 'react';
import { routes } from '../routes';
import { Action, useStateContext } from '../providers/StateContext';
import { Image } from '../assets/images';
import { ButtonList } from '../components/common/ButtonList';
import dayjs from 'dayjs';

export const Home = (props) => {
  const [{ isAuthorized }, dispatch] = useStateContext();

  useEffect(() => {
    dispatch({
      type: Action.SET_NAME,
      name: 'Introduction',
    });

    dispatch({
      type: Action.SET_FILTERS,
      filters: {
        startDate: new Date(
          dayjs().hour(1).minute(0).second(0).subtract(1, 'month').format()
        ),
        endDate: new Date(dayjs().hour(23).minute(59).second(0)),
        activeFilter: 'all',
        status: 'all',
      },
    });
  }, [dispatch]);

  const goTo = (path) => {
    props.history.push(path);
  };

  return (
    <React.Fragment>
      <div>
        <div className="eqha-banner">
          <img src={Image.Banner.src} alt={Image.Banner.alt} />
        </div>
        <div className={'home'}>
          <div className="content">
            <p>
              Welcome to eReferral: Refer a client to health facilities to
              receive full care for their reproductive health, tuberculosis or
              HIV/STI needs.
              <br />
              <br />
              The purpose of the eReferral system is to strengthen pharmacy
              contributions to a number of health priorities including refer a
              client tuberculosis, family planning, HIV and other STIs and other
              health issues as needed.
            </p>
          </div>
          {isAuthorized && (
            <ButtonList
              labels={['Screen a Client', 'Referral History']}
              buttonHandlers={{
                handler1: () => goTo(routes.select.path),
                handler2: () => goTo(routes.history.path),
              }}
            />
          )}
        </div>
      </div>
    </React.Fragment>
  );
};
