export const genderTypes = [
  { value: 'male', name: 'Male' },
  { value: 'female', name: 'Female' },
];

export const statusTypes = {
  Incomplete: 'incomplete',
  Pending: 'pending',
  Declined: 'declined',
  Confirmed: 'confirmed',
  Screened: 'screened',
};

export const translateStatus = (status) => {
  switch (status) {
    case statusTypes.Incomplete:
      return 'Belum lengkap';
    case statusTypes.Pending:
      return 'Menunggu konfirmasi pasien';
    case statusTypes.Declined:
      return 'Ditolak pasien';
    case statusTypes.Confirmed:
      return 'Sudah dikonfirmasi pasien';
    case statusTypes.Screened:
      return 'Tidak dirujuk';
    default:
      return '';
  }
};

export const monthTypes = [
  { value: 'January', name: 'January' },
  { value: 'February', name: 'February' },
  { value: 'March', name: 'March' },
  { value: 'April', name: 'April' },
  { value: 'May', name: 'May' },
  { value: 'June', name: 'June' },
  { value: 'July', name: 'July' },
  { value: 'August', name: 'August' },
  { value: 'September', name: 'September' },
  { value: 'October', name: 'October' },
  { value: 'November', name: 'November' },
  { value: 'December', name: 'December' },
];

export const supportedCountryCodeTypes = ['KH', 'PH'];
